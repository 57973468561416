import { BreadLink, BreadTypography, Capitalize, PageHeader } from "./partials"
import Seo from "./seo"
import Layout from "./Layout"
import * as React from "react"
import { Breadcrumbs, Grid } from "@material-ui/core"
import { Typography } from "@mui/material"
import Image from "./Image"
import { ConsumableAutoComplete } from "./ConsumableAutoComplete"
import { PageContext } from "gatsby/internal"

export const ConsumablePage = ({ pageContext }: PageContext) => {
  const { consumable } = pageContext

  const breadComponent = consumable && (
    <Breadcrumbs aria-label="breadcrumb">
      <BreadLink underline="hover" color="inherit" href="/consumables">
        Consumables
      </BreadLink>
      <BreadTypography> {consumable.title}</BreadTypography>
    </Breadcrumbs>
  )

  return (
    consumable && (
      <Layout
        HeaderComponent={
          <PageHeader
            id={"productsHeader"}
            header={Capitalize(consumable.title)}
          />
        }
        BreadComponent={breadComponent}
        hasFeatured={true}
      >
        <Seo title={consumable.title} />
        <Grid container spacing={4}>
          <Grid
            item
            container
            xs={12}
            md={6}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} md={12} style={{ height: "600px" }}>
              <Image alt={consumable.title} filename={consumable.metadata.image.url} />
            </Grid>
          </Grid>
          {consumable.metadata.options && (
            <Grid
              item
              container
              xs={12}
              md={6}
              alignItems="center"
              justifyContent="center"
            >
              {consumable.metadata.options.map(cat => (
                <ConsumableAutoComplete
                  cat={cat}
                  consumableId={consumable.id}
                  consumable={consumable}
                />
              ))}
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <Typography variant={"h6"} style={{ whiteSpace: "pre-line" }}>
              {consumable.metadata.description}
            </Typography>
          </Grid>
        </Grid>
      </Layout>
    )
  )
}

export default ConsumablePage
