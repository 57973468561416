import { Grid, TextField } from "@material-ui/core"
import { Autocomplete, Button } from "@mui/material"
import * as React from "react"
import {Consumable, ConsumableOptions } from "../types/Types"
import { useState } from "react"
import { ConsumableCartItem } from "../useCart/UseCartTypes"
import { useConsumableCart } from "../useCart/useConsumableCart"

type ConsumableOptionsProps = {
  cat: ConsumableOptions
  consumableId: string
  consumable: Consumable
}

export const ConsumableAutoComplete = ({
  cat,
  consumableId,
    consumable
}: ConsumableOptionsProps) => {
  const [value, setValue] = React.useState<string | null>(cat.values[0])

  const { cart, handleAdd, handleRemove, handleQuantityChange } =
    useConsumableCart()

  const addToCart = () => {
    const newItem: ConsumableCartItem = {
      item: cat.desc + " " + value,
      quantity: 1,
      consumableId: consumableId,
      consumable: consumable
    }
    // @ts-ignore
    handleAdd(newItem)
  }

  return (
    <Grid
      item
      container
      md={12}
      xs={12}
      style={{ width: "100%" }}
      alignItems={"center"}
      justifyContent="center"
    >
      <Grid item md={6} xs={6}>
        <Autocomplete
          style={{
            minWidth: "100%",
          }}
          value={value}
          options={cat.values}
          onChange={(event: any, newValue: string | null) => {
            setValue(newValue)
          }}
          renderInput={params => (
            <TextField
              {...params}
              label={cat.desc}
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Button variant={"contained"} onClick={addToCart}>
          Add To Cart
        </Button>
      </Grid>
    </Grid>
  )
}
